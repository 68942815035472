import { graphql, useStaticQuery } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import Layout from '../../../components/Layout'
import SEO from '../../../components/SEO'
import './CommunityGuidelines.scss'

const CommunityGuidelines = () => {
  const {
    mdx: {
      body,
    },
  } = useStaticQuery(graphql`
    query CommunityGuidelinesQuery {
      mdx(fields: { title: { eq: "CommunityGuidelines" } } ) {
        body
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Community Guidelines"
      />
      <div className="community-guidelines-page">
        <MDXRenderer>{body}</MDXRenderer>
      </div>
    </Layout>
  )
}

export default CommunityGuidelines